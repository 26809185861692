import Vue from 'vue'

export default {
  SET (state, payload) {
    state.group = Object.assign({}, state.group, payload) //precisa ser assim para manter a reatividade
  },
  SET_GROUPS (state, payload) {
    state.groups = payload
  },
  SET_GROUPS_GRID (state, payload) {
    state.groupsGrid = payload
  },
  STORE (state, payload) {
    state.groups.push(payload) //precisa ser assim para manter a reatividade
    state.groups.sort((a, b) => { return a.group.localeCompare(b.group) }) // Ordena por nome

    state.groupsGrid.data.push(payload)
    state.groupsGrid.data.sort((a, b) => { return a.group.localeCompare(b.group) }) // Ordena por nome
    state.groupsGrid.total = state.groupsGrid.total + 1
  },
  UPDATE (state, payload) {
    const idx = state.groups.findIndex(o => o.id === payload.id)
    if (idx >= 0) Vue.set(state.groups, idx, payload)

    const idx1 = state.groupsGrid.data.findIndex(o => o.id === payload.id)
    if (idx1 >= 0) Vue.set(state.groupsGrid.data, idx1, payload)
  },
  DELETE (state, payload) {
    if (state.group && state.group.id === payload) {
      state.group = Object.assign({}, {}) //precisa ser assim para manter a reatividade
    }

    const idx1 = state.groups.findIndex(o => o.id === payload)
    if (idx1 >= 0) state.groups.splice(idx1, 1) //precisa ser assim para manter a reatividade

    const idx = state.groupsGrid.data.findIndex(o => o.id === payload)
    if (idx >= 0) {
      state.groupsGrid.data.splice(idx, 1) //precisa ser assim para manter a reatividade
      state.groupsGrid.total = state.groupsGrid.total - 1
    }
  }
}
