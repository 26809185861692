export default {
  getGroups (state) {
    const groups = []
    for (const k in state.groups) {
      groups.push({
        label: state.groups[k].group,
        value: state.groups[k].id
      })
    }
    return groups
  }
}
